import React from 'react'
import Container from './container'
import FeatureBlock from './feature-block'
import * as styles from './features.module.css'

const Features = ({ features }) => {
  return (
    <Container>
      <div className={styles.features}>
        <FeatureBlock
          title={features.dataOneTitle}
          subtitle={features.dataOneSubtitle}
        />
        <FeatureBlock
          title={features.dataTwoTitle}
          subtitle={features.dataTwoSubtitle}
        />
        <FeatureBlock
          title={features.dataThreeTitle}
          subtitle={features.dataThreeSubtitle}
        />
      </div>
    </Container>
  )
}

export default Features
