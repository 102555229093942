import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import CaseStudyHeader from '../components/case-study-header'
import ShowcaseImage from '../components/showcase-image'
import ShowcaseImageDouble from '../components/showcase-image-double'
import Features from '../components/features'
import TextBlock from '../components/text-block'
import TextBlockWithImage from '../components/text-block-with-image'
import SpacerRegular from '../components/spacer-regular'
import SpacerLarge from '../components/spacer-large'
import SpacerMedium from '../components/spacer-medium'

class CaseStudyWalkies extends React.Component {
  render() {
    const [header] = get(this, 'props.data.header.nodes')
    const [showcaseImage] = get(this, 'props.data.showcaseImage.nodes')
    const [statistics] = get(this, 'props.data.statistics.nodes')
    const [problem] = get(this, 'props.data.problem.nodes')
    const [showcaseDouble] = get(this, 'props.data.showcaseDouble.nodes')
    const [solution] = get(this, 'props.data.solution.nodes')
    const [textBlockOne] = get(this, 'props.data.textBlockOne.nodes')
    const [textBlockTwo] = get(this, 'props.data.textBlockTwo.nodes')
    const [textBlockThree] = get(this, 'props.data.textBlockThree.nodes')
    const [showcaseFinalImage] = get(this, 'props.data.showcaseFinalImage.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Case Study: Walkies" />
        <CaseStudyHeader
          title={header.title}
          subtitle={header.subtitle}
          description={header.description.description}
        />
        <ShowcaseImage image={showcaseImage.image}/>
        <SpacerRegular/>
        <Features features={statistics}/>
        <SpacerRegular/>
        <TextBlock
          title={problem.title}
          subtitle={problem.subtitle.subtitle}
        />
        <SpacerRegular/>
        <ShowcaseImageDouble
          imageLeft={showcaseDouble.imageLeft}
          imageRight={showcaseDouble.imageRight}
        />
        <SpacerMedium/>
        <TextBlock
          title={solution.title}
          subtitle={solution.subtitle.subtitle}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={textBlockOne.title}
          subtitle={textBlockOne.subtitle.subtitle}
          image={textBlockOne.image}
          leftImage={textBlockOne.leftImage}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={textBlockTwo.title}
          subtitle={textBlockTwo.subtitle.subtitle}
          image={textBlockTwo.image}
          leftImage={textBlockTwo.leftImage}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={textBlockThree.title}
          subtitle={textBlockThree.subtitle.subtitle}
          image={textBlockThree.image}
          leftImage={textBlockThree.leftImage}
        />
        <SpacerLarge/>
        <ShowcaseImage image={showcaseFinalImage.image}/>
      </Layout>
    )
  }
}

export default CaseStudyWalkies

export const pageQuery = graphql`
  query CaseStudyWalkiesQuery {
    header: allContentfulHeader(
      filter: { contentful_id: { eq: "4l2Q9MyFOFaYxagY886m9D" } }
    ) {
      nodes {
        title
        subtitle
        description {
          description
        }
      }
    }
    showcaseImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "12XnTxCIszfE7JfDdX55KY" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    statistics: allContentfulDataSet(
      filter: { contentful_id: { eq: "4Ye3tvu61xExl4oQxD9kWq" } }
    ) {
      nodes {
        dataOneTitle
        dataOneSubtitle
        dataTwoTitle
        dataTwoSubtitle
        dataThreeTitle
        dataThreeSubtitle
      }
    }
    problem: allContentfulTextBlock(
      filter: { contentful_id: { eq: "5iwKQa1V4YDXsCYDLJ2qRB" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    showcaseDouble: allContentfulShowcaseImageDouble(
      filter: { contentful_id: { eq: "3sDIZgiQIxKxgrYztqNAWE" } }
    ) {
      nodes {
        imageLeft {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
        imageRight {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    solution: allContentfulTextBlock(
      filter: { contentful_id: { eq: "01ITqIicJsivzgy2hbKoFM" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    textBlockOne: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "1vlx5RAnGQD7DmmvF2CDpq" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    textBlockTwo: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "5Zy7TpjnpakRN0XFkkTP6x" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    textBlockThree: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "4CGk68Sax9KfN4oN0j2D3J" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    showcaseFinalImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "17Zb1rf7RfnW4mhbqSwRYD" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
  }
`
