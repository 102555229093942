import React from 'react'
import Container from './container'
import * as styles from './feature-block.module.css'

const FeatureBlock = ({ title, subtitle }) => (
  <div className={styles.featureBlock}>
    <h1 className={styles.title}>{title}</h1>
    <h5 className={styles.subtitle}>{subtitle}</h5>
  </div>
)

export default FeatureBlock
